header .smHeader.addMargin {
    margin-bottom: 98px;
}
header .smHeader {
    background-color: var(--themeBg);
    padding: 10px 0;
}
header .smHeader .innerRow {
    display: flex;
    justify-content: space-between;
    /* color: #fff; */
    color: var(--textDark);
    font-size: 12px;
    flex-wrap: wrap;
}
header .smHeader .innerRow .col1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
header .smHeader .innerRow .col1 .contact {
    margin-right: 20px;
}
header .smHeader .innerRow .col1 .contact i {
    margin-right: 5px;
    font-size: 14px;
    color: var(--textDark);
}
header .smHeader .innerRow .col1 .contact a:hover {
    text-decoration: none;
    color: #fff;
    transition: all 0.3s;
}
header .smHeader .innerRow .col1 .contact a {
    color: var(--textDark);
    transition: all 0.3s;
}
header .smHeader .socialMain {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
header .smHeader .socialMain a {
    display: inline-block;
    padding: 0 10px;
    /* color: #fff; */
    color: var(--textDark);
    transition: all 0.3s;
}
header .smHeader .socialMain i {
    font-size: 14px;
}
header .smHeader .socialMain a:hover {
    color: var(--themeRed);
    transition: all 0.3s;
    transform: scale(1.3);
}
header .navbar.navStick {
    position: fixed;
    transition: all 0.4s;
    animation: mymove 0.5s ;
}

@keyframes mymove {
  from {top: -138px;}
  to {top: 0px;}
}
header .navbar {
    padding: 20px 0;
    background-color: var(--themeBg1);
    position: relative;
    top: 0;
    width: 100%;
    transition: all 0.4s;
    z-index: 100;
}
/* header .navbar::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url(../../assets/images/navBg.png);
    background-size: 100% 100%;
    opacity: 0.3;
    z-index: 0;
} */
.z-1 {
    z-index: 1;
}
header .navbar .navbar-brand {
    max-width: 200px;
    margin-right: 0;
}
header .navbar .nav-link:hover {
    color: var(--themeRed) !important;
    transition: all 0.4s;
}
header .navbar .nav-link {
    color: var(--textDark) !important;
    text-transform: uppercase;
    font-size: 14px;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-weight: bold;
    position: relative;
    transition: all 0.4s;
}

header .navbar .navbar-toggler i {
    color: var(--textDark);
}
header .navbar .nav-link::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 10px;
    width: 0%;
    height: 2px;
    background-color: var(--themeRed);
    /* transform: translate(-50% , 0); */
    transition: all 0.4s;
}
button:focus {
    outline: none !important;
}
header .flag {
    font-weight: bold;
}
header .flag img {
    width: 40px;
    padding: 8px;
    border-radius: 6px;
    background-color: #e3e3e3;
    margin-right: 10px;
}
.themeBtn {
    text-transform: uppercase;
    padding: 8px 25px;
    border-radius: 3px;
    background-color: var(--themeRed);
    border: 2px solid var(--themeRed);
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    outline: none;
    transition: all 0.3s;
}
.themeBtn:hover {
    opacity: 0.9;
    color: #fff;
    text-decoration: none;
    transform: scale(1.1);
    transition: all 0.3s;
}

@media screen and (min-width:992px) {
    header .navbar .nav-link:hover::before {
        width: calc( 100% - 20px );
        transition: all 0.4s;
    }
}
@media screen and (max-width:992px) {
    header .smHeader .innerRow {
        justify-content: center;
    }
    header .smHeader .innerRow .col1 {
        margin-bottom: 10px;
    }
    header .navbar .contactDrop {
        margin-top: 20px;
    }
}
@media screen and (max-width:576px) {
    header .navbar {
        padding-left: 15px;
        padding-right: 15px;
    }
 

}