.recieptMain {
    padding: 15px;
    max-width: 850px;
    margin: auto;
    background-color: #fff;
    font-size: 14px;
    position: relative;
    min-height: 1500px;
}
.recieptMain .logo {
    max-width: 220px;
    margin: auto;
    margin-bottom: 20px;
}
.recieptMain .heading {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin: 10px 0px 20px 0;
}
.recieptMain .tableM {
    border: 1px solid var(--themeRed);
    height: 100%;
}
.recieptMain .tableM .tabHead {
    padding: 10px;
    background-color: var(--themeRed);
    color: #fff;
    font-weight: 500;
}
.recieptMain .tableM .tableContent {
    padding: 5px 10px;
}
.recieptMain .tableM .tableContent .infoRow {
    display: flex;
    align-items: center;
    padding: 4px 0;
}
.recieptMain .tableM .tableContent .infoRow .title {
    width: 40%;
    font-weight: bold;
}
.recieptMain .tableM .tableContent .infoRow .val {
    width: 60%;
}
.recieptMain .tableM .tableContent .infoRow .pMethod span {
    position: relative;
}
.recieptMain .tableM .tableContent .infoRow .pMethod span i {
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    bottom: -10px;
    font-size: 17px;
    opacity: 0.7;
    color: green;
}
.recieptMain .tableM .tableContent .infoRow .dropdown button {
    width: 100%;
    text-align: left;
}
.recieptMain input, textarea,select {
    width: 100%;
    border: none;
    font-weight: 500;
}
.recieptMain .titleHead {
    font-size: 20px;
    color: var(--themeRed);
    border-bottom: 2px solid var(--themeRed);
    padding: 5px 10px;
}
.recieptMain .pageName {
    padding: 5px 10px;
    font-weight: bold;
}
.recieptMain .pageVal {
    font-weight: 500;
    padding: 5px 10px;
}
.recieptMain input {
    border: none;
    font-weight: 500;
}
.text-right {
    text-align: right;
}
.recieptMain .textAreaM {
    font-weight: 500;
    padding: 5px 10px;
    padding-top: 0;
}
.recieptMain .footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.recieptMain .footer .underline{
    display: inline-block;
    width: 150px;
    height: 2px;
    background: #c4c4c4;
    margin-left: 40px;
}
.recieptMain .footerMini {
    margin-top: 40px;
    padding: 20px 0;
    border-top: 1px solid #c4c4c4;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.recieptMain .footerMini .address {
    padding: 0 10px;
}
.recieptMain .footerMini .site {
    padding: 0 10px;
    color: black;
    font-weight: 500;
}
