@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --themeBg:#1f1f1f;
  --themeBg1:#282828;
  --themeBg2:#313131;
  --themeBgLight:rgb(240, 242, 245);
  /* --themeBg:#282828; */
  --themeRed:#d30606;
  /* --themeRed:#f00000; */
  --textDark:#969595;
}
.pr {
  position: relative;
}
.d-flex {
  display: flex;
}
.ai-center {
  align-items: center;
}
.jc-center {
  justify-content: center;
}
.dib {
  display: inline-block;
}
img {
  max-width: 100%;
}
.BannerSec {
  /* min-height: 400px; */
  /* background: url(./assets/images/bannerImg.jpg); */
  background-size: cover;
  background-position: center;
  /* display: flex;
  align-items: center; */
}
.BannerSec .numOfVehicle {
  color: #fff;
}
.BannerSec .numOfVehicle .num {
  font-size: 70px;
  font-weight: bold;
  line-height: 1;
  color: var(--themeRed);
}
.BannerSec .numOfVehicle .title {
  font-size: 24px;
  font-weight: bold;
}
.filterMain {
  position: relative;
  top: -88px;
  z-index: 1;
}
.filterMain .filterRow {
  display: flex;
  padding: 35px;
  /* background: #153e4b; */
  background: var(--themeBg);
}
.filterMain .filterRow .form-group {
  margin-bottom: 0 !important;
  width: 100%;
  margin-right: 16px;
}
.filterMain .filterRow .form-group input {
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
}
.filterMain .filterRow .form-group .input {
  height: 46px;
  background-color: #fff;
  width: 100%;
  border: none;
}
.filterMain .filterRow .form-group .input .css-13cymwt-control {
  border: none;
  min-height: 46px;
}
.filterMain .filterRow .form-group .input .css-t3ipsp-control {
  border: none;
  min-height: 46px;
}
.filterMain .themeBtn {
  font-weight: bold;
  font-size: 14px;
  flex-shrink: 0;
  padding-left: 40px;
  padding-right: 40px;
  outline: none;
}
.filterMain .btnMain {
  display: flex;
  overflow-y: auto;
}
.filterMain .btnMain button.active {
  background-color: var(--themeBg);
  color: #fff;
}
.filterMain .btnMain button {
  background-color: var(--themeBg1);
  border: none;
  padding: 12px 35px;
  color: var(--textDark);
  font-size: 15px;
  font-weight: 500;
  outline: none;
}
.homePage .brawseByMakeSec {
  padding-bottom: 50px;
}
.homeHeading {
  color: #232628;
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 30px;
}
.homeHeading span {
  color: var(--themeRed);
}
.homePage .brawseByMakeSec .makeRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.homePage .brawseByMakeSec .makeRow .nameCol {
  padding: 0 10px ;
  max-width: 100px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.homePage .brawseByMakeSec .makeRow .nameCol .imgDiv {
  min-height: 60px;
}
.homePage .brawseByMakeSec .makeRow .nameCol .title {
  color: #777777;
  /* text-decoration: underline; */
  font-size: 13px;
}
.homePage .statsSec {
  padding: 100px 0;
  background-attachment: fixed;
  background-image: url(./assets/images/statsBanner.jpg);
  background-position: center center;
  background-size: cover;
  color: #fff;
}
.homePage .statsSec .stats {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homePage .statsSec .stats .ico {
  width: 56px;
  /* height: 56px; */
  margin-right: 10px;
  flex-shrink: 0;
}
.homePage .statsSec .stats h3 {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1;
}
.homePage .statsSec .stats .title {
  font-size: 14px;
}

footer {
  background-color: var(--themeBg);
}
footer .row1 {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
footer .row2 {
  padding: 25px 0;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .logo {
  max-width: 200px;
}
footer .row1 .linkM a {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none !important;
  padding: 0 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
footer .row1 .linkM a:hover {
  color: #fff;
}
footer .row1 .linkM a:last-child {
  border: none;
}

footer .row2 .text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}
footer .row2 .socialMain {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
footer .row2 .socialMain a {
  display: inline-block;
  padding: 0 10px;
  color: var(--textDark);
  transition: all 0.3s;
}
footer .row2 .socialMain a:hover {
  transform: scale(1.2);
  transition: all 0.3s;
  color: var(--themeRed);
}
footer .developByM {
  color: rgba(255, 255, 255, 0.5);
  padding: 10px 0;
  font-size: 13px;
  text-align: right;
}
footer .developByM a {
  color: var(--themeRed);
  font-size: 14px;
  font-weight: 500;
}

.homePage .reviewSec {
  padding: 50px 0;
}
.homePage .reviewSec .reviewCard {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.homePage .reviewSec .reviewCard .name {
  color: #232628;
  font-size: 15px;
  font-weight: bold;
}
.homePage .reviewSec .reviewCard .desc {
  font-size: 13px;
  color: #232628;
  min-height: 39px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
}
.homePage .reviewSec .reviewCard .imgDiv {
  width: 160px;
  max-height: 120px;
  margin-right: 20px;
  flex-shrink: 0;
}
.homePage .reviewSec .reviewCard .stars {
  display: flex;
  align-items: center;
}
.homePage .reviewSec .reviewCard .stars i {
  font-size: 20px;
  color: orange;
}
.homePage .reviewSec .reviewCard .stars img {
  width: 20px;
}
.homePage .reviewSec .reviewCard .revOn {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
.homePage .reviewSec .reviewCard .imgDiv img {
  width: 100%;
  max-height: 100%;
  border-radius: 10px;
}
.slick-prev:before, .slick-next:before {
  color: #000 !important;
}
.featuredSec {
  padding: 50px 0;
}
.featuredSec .countryBtnRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.featuredSec .countryBtnRow button {
  padding: 7px 12px;
  max-width: 100px;
  width: 100%;
  border: none;
  background-color: var(--themeBg1);
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 500;
}
.featuredSec .countryBtnRow button.active {
  background-color: var(--themeRed);
}
 .productCard {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #88888857;
  padding: 8px;
  position: relative;
  top: 0;
  max-width: 260px;
  margin: auto;
  transition: all 0.3s;
}
 .productCard:hover {
  box-shadow: 0px 6px 5px -1px rgba(0,0,0,0.4);
  transition: all 0.3s;
  top: -8px;
}
 .productCard .imgDiv {
  height: 155px;
  width: 100%;
  background: var(--themeBg);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

 .productCard .imgDiv img {
  width: 100%;
  height: auto;
  min-height: 100%;
  object-fit: cover;
}
 .productCard .titleRow {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  padding: 16px 0;
  border-bottom: 1px solid #ccc;
}
 .productCard .titleRow .title {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
}
 .productCard .titleRow .price {
  background: var(--themeRed);
  color: #fff;
  font-weight: bold;
  flex-shrink: 0;
  padding: 0 8px;
  padding-left: 15px;
  min-height: 35px;
  margin-left: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  max-height: 42px;
  max-width: 100px;
  clip-path: polygon(11% 0, 100% 0%, 100% 100%, 0% 100%);
  line-height: 1.1;
}
 .productCard .statsRow {
  display: flex;align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #888888;
}
 .productCard .statsRow img, .productCard .statsRow i { 
  width: 14px;
  font-size: 14px;
}
.contactPage {
  background: var(--themeBgLight) !important;
}
.contactPage .addressMain {
  padding-bottom: 50px;
}
.contactPage .addressMain .addressCard {
  background: white;
  height: 100%;
}
.contactPage .addressMain .addressCard .addressCardInner {
  padding: 20px;
}
.contactPage .addressMain .addressCard .addressHead {
  padding: 20px;
  background-color: var(--themeBg);
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.contactPage .addressMain .addressCard .addressRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.contactPage .addressMain .addressCard .addressRow .icon {
  font-size: 31px;
  margin-right: 15px;
}
.contactPage .addressMain .addressCard .addressRow .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.contactPage .addressMain .addressCard .addressRow .text {
  font-size: 14px;
  color: #888;
}
.contactPage .ourTeam {
  padding: 50px 0;
}
.contactPage .ourTeam .teamCard {
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
}
.contactPage .ourTeam .teamCard .icon i {
  color: #888;
  font-size: 35px;
}
.contactPage .ourTeam .teamCard .name {
  font-size: 22px;
  font-weight: bold;
}
.contactPage .ourTeam .teamCard .desig {
  color: #888;
  font-size: 16px;
  text-transform: uppercase;
}
.contactPage .ourTeam .teamCard .contact {
  color: #888;
  font-size: 16px;
}
.alertModelMain {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(8px) brightness(0.6);
  z-index: 1000;
}
.alertModelMain .alertModel {
  max-width: 400px;
  background: #fff;
  padding: 40px;
  border-radius: 30px;
  width: 100%;
}
.alertModelMain .alertModel .logo {
  max-width: 150px;
  margin: auto;
  margin-bottom: 30px;
}
.alertModelMain .alertModel .msg {
  color: #000;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
}
.aboutPage {
  background-color: #010103;
}
.aboutPage .bannerSecAbout {
  min-height: 600px;
  background: url(./assets/images/aboutBanner.jpg);
  background-size: cover;
  background-position: center;
  padding: 50px 0;
}
.aboutPage .bannerSecAbout .heading {
  text-align: center;
  font-size: 60px;
  font-weight: bold;
  text-transform: uppercase;
}
.aboutPage .aboutSec {
  padding: 50px 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.5) 59%, rgba(0, 0, 0, 0.8) 100%), url(./assets/images/aboutBg1.jpg);
  background-size: cover;
  background-position: center;
}
.aboutPage .aboutSec .aboutRow {
  display: flex;
  align-items: center;
  color: #fff;
  margin: 50px 0;
}
.aboutPage .aboutSec .aboutRow .imgDivBorder {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 220px;
  height: 220px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.aboutPage .aboutSec .aboutRow .imgDiv {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.aboutPage .aboutSec .aboutRow .imgDiv img {
  background-color: #fff;
}
.aboutPage .aboutSec .aboutRow .line {
  width: 100px;
  height: 1px;
  background-color: #fff;
}
.aboutPage .aboutSec .aboutRow .circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--themeRed);
  flex-shrink: 0;
}
.aboutPage .aboutSec .aboutRow .content {
  margin-left: 30px;
}
.aboutPage .aboutSec .aboutRow.right .content {
  margin-left: 0;
  margin-right: 30px;
}
.companyInfoSec {
  padding: 50px 30px;
  background-color: var(--themeBg);
  border-radius: 30px;
}
.companyInfoSec .compRow {
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.companyInfoSec .compRow .compCard:last-child {
  border: none;
}
.companyInfoSec .compRow .compCard {
  padding: 30px 20px;
  width: 100%;
  border-right: 1px solid var(--themeBg2);
  text-align: center;
}
.companyInfoSec .compRow .compCard .name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.companyInfoSec .compRow .compCard .val {
  color: var(--textDark);
  font-size: 15px;
}
.companyInfoSec .heading {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
.companyInfoSec .textRow {
  display: flex;
  background-color: #e3e3e3;
  border-bottom: 3px solid #000;
  border-top: 3px solid #000;
}
.companyInfoSec .textRow .name {
  max-width: 300px;
  width: 100%;
  color: var(--themeRed);
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 500;
  border-right: 5px solid #000;
}
.companyInfoSec .textRow .val {
  font-weight: 500;
  padding: 10px 20px;
}

/* stock Page */
.stockPage .stockMain {
  padding: 50px 0;
}
.stockPage .filterSidebar {
  padding: 20px;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.27);
  background: var(--themeBg2);
}
.stockPage .stockMain .filterSidebar .r1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 20px;
}
.stockPage .stockMain .filterSidebar .r1 .title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}
.stockPage .stockMain .filterSidebar .r1 .ico {
  font-size: 25px;
}
.stockPage .stockMain .filterSidebar .themeBtn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.stockPage .stockMain .filterSidebar .themeBtn {
  font-weight: bold;
  letter-spacing: 1px;
}
.stockPage .stockMain .filterSidebar .inputStyle {
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  width: 100%;
  padding: 3px 10px;
}
.stockPage .stockMain .filterSidebar .reset {
  font-size: 14px;
  color: #fff;
  text-decoration: underline;
  text-align: right;
  margin-top: 10px;
  cursor: pointer;
}
.stockPage .stockMain .filterSidebar .reset:hover {
  color: var(--themeRed);
}
.stockPage .stockMain .filterSidebar .closeBtn {
  font-size: 20px;
  text-align: right;
  cursor: pointer;
  display: none;
  color: #fff;
  margin-bottom: 20px;
}
.stockPage .stockMain .stckSec .head {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #D5D4D4;
}
.stockPage .stockMain .stckSec .head .title {
  color: #232628;
  font-family: "Montserrat", Sans-serif;
  font-size: 26px;
  font-weight: 700;
}
.stockPage .stockMain .stckSec .r2 {
  margin-bottom: 25px;
  position: relative;
  padding-right: 120px;
  border-bottom: 3px solid var(--themeRed);
}
.stockPage .stockMain .stckSec .r2 .title {
  position: relative;
  display: inline-block;
  padding: 3px 16px;
  padding-right: 40px;
  color: #fff;
  background-color: var(--themeRed);
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
}
/* stock Page */

.noDataMain {
  padding: 40px;
  min-height: calc( 100vh - 310px);
  text-align: center;
}
/* detail page */
.detailPage .detailPageMain {
  padding: 50px 0;
}
.detailPage .detailPageMain .col1 .row1 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.detailPage .detailPageMain .col1 .row1 .name {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: #232628;
}
.detailPage .detailPageMain .col1 .row1 .price {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  background-color: var(--themeRed);
  padding: 12px;
  padding-left: 40px;
  clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
  margin-bottom: 20px;
}
.detailPage .detailPageMain .functionMain {
  padding: 30px 0;
}
.detailPage .detailPageMain .functionMain ul {
  display: grid;
  list-style: none;
  margin: 0 -15px;
  padding: 0;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.detailPage .detailPageMain .functionMain ul li {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 8px 15px;
  margin: 0;
  position: relative;
}
.detailPage .detailPageMain .functionMain ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #d5d9e0;
  margin: 0 15px;
}
.detailPage .detailPageMain .functionMain ul li .item-label {
  display: block;
  font-size: 13px;
  padding: 5px 5px 5px 0;
  color: #888888;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 45%;
  flex-grow: 1;
}
.detailPage .detailPageMain .functionMain ul li .item-label svg,
.detailPage .detailPageMain .functionMain ul li .item-label i {
  display: inline-block;
  position: relative;
  top: 3px;
  font-size: 18px;
  min-width: 33px;
  padding-right: 5px;
  text-align: center;
  vertical-align: top;
  max-height: 22px;
  color: #1bc741;
}
.detailPage .detailPageMain .functionMain ul li .heading-font {
  padding: 5px 0 5px 10px;
  color: #222;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 55%;
  flex-grow: 1;
  text-transform: capitalize;
}
.detailPage .detailPageMain .featureMain {
  border-top: 4px solid #153E4B;
  border-bottom: 4px solid #153E4B;
  padding-top: 25px;
  padding-bottom: 10px;
}
.detailPage .detailPageMain .featureMain .heading {
  color: #153E4B;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.detailPage .detailPageMain .featureMain .fRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.detailPage .detailPageMain .featureMain .fRow .fCol {
  width: 25%;
  margin-bottom: 15px;
}
.detailPage .detailPageMain .featureMain .fRow .fCol h4 {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
}
.detailPage .detailPageMain .featureMain .fRow .fCol .FeatureRow {
  display: flex;
  align-items: center;
  color: #232628;
  font-size: 14px;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.detailPage .detailPageMain .featureMain .fRow .fCol .FeatureRow .ico {
  margin-right: 13px;
  color: #1bc741;
  line-height: 20px;
}
.detailPage .detailPageMain .descMain {
  padding: 25px 0;
}
.detailPage .detailPageMain .descMain .heading{
  color: #153E4B;
  /* font-family: "Montserrat", Sans-serif; */
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.detailPage .detailPageMain .descMain .description {
  font-size: 14px;
}
.detailPage .detailPageMain .col2 .phone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  border-top: 1px solid #e5e8ec;
  color: var(--themeRed);
  font-size: 18px;
  font-weight: bold;
  margin-top: 80px;
}
.detailPage .detailPageMain .col2 .phone .ico {
  margin: 0px 17px 0px 0px;
}
.detailPage .detailPageMain .col2 .whatsapp {
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
  letter-spacing: normal;
  text-align: left;
  background-color: #ffffff;
  color: #153e4d;
  border-color: #e0e3e7;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.09);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 18px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 5px 5px 5px 5px;
  padding: 13px 17px 13px 17px;
}
.detailPage .detailPageMain .col2 .whatsapp .ico {
  font-size: 26px;
  margin: 0px 17px 0px 0px;
  color: #1bc741;
}
.detailPage .detailPageMain .col2 .whatsapp.email .ico {
  color: var(--themeBg2);
}

.detailPage .detailPageMain .col2 .location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  border-top: 1px solid #e5e8ec;
  font-size: 14px;
}
.detailPage .detailPageMain .col2 .location .ico {
  font-size: 18px;
  margin-right: 1em;
  color: var(--themeRed);
}


/* detail page */

/* login page */
.loginPage {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 309.8px);
  background-color: var(--themeBgLight) !important;
}
.loginPage .formCard {
  padding: 25px;
  max-width: 600px;
  width: 100%;
  background-color: #fff;
}
.loginPage .formCard .logo {
  width: 250px;
  margin: auto;
  margin-bottom: 30px;
}
.loginPage .formCard .themeBtn {
  width: 100%;
  margin-top: 20px;
}
.loginPage .formCard .themeBtn:hover {
  transform: unset;
  opacity: 0.8;
}
.loginPage .formCard input,.loginPage .formCard input::placeholder {
  color: var(--textDark);
}

.detailPage .detailPageMain  .galleryMain.addWaterMark .image-gallery-slide.image-gallery-center::after {
  position: absolute;
  content: 'Pak Auto Source';
  font-size: 42px;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  /* transform: translate(-50%, -50%); */
  color: #e7e7e780;
  justify-content: center;
  align-items: center;
}
.max-width-400 {
  max-width: 400px;
}

/* login page */
.seekoLoader {
  position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
   left: 0;
  height: 100vh;
  background: rgba(0,0,0,.2);
  z-index: 1111;
}
.seekoLoader .loader {
      width: 100%;
      max-width: 280px;
      padding: 20px 0;
      text-align: center;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      background: #fff;
      border-radius: 20px;
      position: fixed;
  }

 
  .seekoLoader .logo {
      width: 180px;
      /* animation: beat .4s infinite alternate; */
  }
      /* Heart beat animation */
      /* @keyframes beat{
        to { transform: scale(1.3); 
        }
    } */
    
  .jc-sb {
    justify-content: space-between;
  }
  .shareModalMain {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    backdrop-filter: blur(4px) brightness(0.5);
  }
  .shareModalMain .shareModal {
    padding: 30px;
    max-width: 300px;
    width: 100%;
    background: var(--themeBg);
    color: #fff;
    border-radius: 20px;
  }
  .shareModalMain .shareModal .text {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--textDark);
  }
  .shareModalMain .shareModal .closeIco {
    text-align: right;
    font-size: 19px;
  }
  .shareMain {
    font-size: 20px;
    text-align: right;
    position: relative;
    top: -9px;
  }
@media screen and (min-width:992px) and (max-width:1200px) {
  .aboutPage .bannerSecAbout {
    min-height: 450px;
  }
}
@media screen and (min-width:768px) and (max-width:992px) {
  .aboutPage .bannerSecAbout {
    min-height: 400px;
  }
  .filterMain {
    top: -60px;
  }
}
@media screen and (min-width:576px) and (max-width:768px) {
  .aboutPage .bannerSecAbout {
    min-height: 350px;
  }
  .detailPage .detailPageMain .functionMain ul {
    grid-template-columns: 50% 50%;
  }
  .filterMain {
    top: -40px;
  }
  .filterMain .btnMain button {
    font-size: 13px;
  }
}
@media screen and (max-width:992px) {

  .homePage .brawseByMakeSec .makeRow {
    justify-content: center;
  }
  .homePage .brawseByMakeSec .makeRow .nameCol {
    margin-bottom: 20px;
  }
  .stockPage .stockMain .filterSidebar .closeBtn {
    display: block;
  }
  /* .stockPage .filterSidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    bottom: 0;
    z-index: 111;
    padding-top: 100px;
  } */
}

@media screen and (max-width:768px) {
  .homePage .statsSec .stats {
    margin-bottom: 20px;
  }
  .filterMain .filterRow {
    flex-direction: column;
  }
  .filterMain .filterRow .form-group {
    margin-bottom: 12px !important;
  }
  .filterMain .btnMain button {
    padding: 10px 20px;
  }
  .companyInfoSec .compRow {
    flex-direction: column;
  }
  .companyInfoSec .compRow .compCard {
    border-right: none;
    border-bottom: 1px solid var(--themeBg2);
  }
}
@media screen and (max-width:576px) {
  .homePage .reviewSec {
    padding: 50px 15px;
  }
  .homePage .reviewSec .reviewCard {
    flex-direction: column;
  }
  .homePage .reviewSec .reviewCard .imgDiv {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .homePage .reviewSec .reviewCard .imgDiv {
    width: auto;
    max-width: 200px;
    height: 140px;
  }
  .aboutPage .bannerSecAbout {
    min-height: 180px;
  }
  .aboutPage .aboutSec .aboutRow {
    flex-wrap: wrap;
  }
  .aboutPage .aboutSec .aboutRow .content {
    margin-left: 0;
    margin-top: 20px;
  }
  .aboutPage .aboutSec .aboutRow.right .content {
    order: 2;
  }
  .aboutPage .aboutSec .aboutRow.right .circle {
    margin-left: auto;
  }
  .aboutPage .aboutSec .aboutRow .imgDivBorder {
    width: 170px;
    height: 170px;
  }
  .aboutPage .aboutSec .aboutRow .line {
    width: 60px;
  }
  .companyInfoSec .textRow .name {
    width: 180px;
    flex-shrink: 0;
  }
  .companyInfoSec .textRow .name {
    font-size: 15px;
  }
  .companyInfoSec .textRow .val {
    font-size: 15px;
  }
  .companyInfoSec .heading {
    font-size: 22px;
  }
  footer .row1 {
    flex-direction: column;
  }
  footer .row1 .linkM {
    margin-top: 10px;
  }
  footer .row2 {
    flex-direction: column;
  }
  footer .row2 .socialMain {
    order: 1;
  }
  footer .row2 .text {
    order: 2;
  }
  .detailPage .detailPageMain .functionMain ul {
    grid-template-columns: 100%;
  }
  .detailPage .detailPageMain .featureMain .fRow .fCol {
    width: 50%;
  }
  .detailPage .detailPageMain .col1 .row1 .name {
    font-size: 21px;
    text-transform: capitalize;
  }
  .detailPage .detailPageMain .col1 .row1 .price {
    font-size: 18px;
  }
  header .smHeader .innerRow .col1 {
    justify-content: center;
  }
  .filterMain {
    top: 0px;
    padding: 30px 0;
  }
  .filterMain .btnMain button {
    font-size: 12px;
    padding: 10px;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-size: 20px !important;
  }
  
}
