.dib{
    display: inline-block;
}
.drivers-row .driver-row-link{
    color: #3C4253;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.4;
    text-decoration: none;
}
.drivers-row .driver-row-link:hover,.drivers-row .driver-row-link:focus{
    opacity: 0.8;
    color: #3C4253;
    text-decoration: none;
    
}
.vertically-center{
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.border-white{
    border-color: white;
}
.text-end{
    text-align: end;
}
.driver-2nd-col{
    text-align: end;
}
@media(max-width:992px){
    .drivers-row h4{
        margin-right: 0 !important;
    }
}
.drivers-table .img-profile {
    width: 2rem;
    height: 2rem;
    margin-right: 10px;
}
.drivers-table .maker-logo {
    width: 100px;
}
.table-text-dark{
    color: #3C4253 !important;
    opacity: 1;
    font-weight: 600;
    font-size: 14px;
    width: max-content;
}
.table-text-light{
    color: #3C4253 !important;
    opacity: 0.4;
    font-weight: 600;
    font-size: 12px;
    width: max-content;
}
.w-max-c{
    width: max-content;
}
.db{
    display: block;
}
.drivers-row label{
    padding: 6px 12px;
    display: table;
    color: black;
  cursor:pointer;
    border-radius:.25rem;
}
.drivers-row input[type="file"] {
    display: none;
}
.jc-fe{
    justify-content: flex-end;
}
.diverAddBtn{
    width: 80px;
    height: 80px;
    background-image: linear-gradient(to right, var(--themeRed), var(--themeBg));
    display: flex;justify-content: center;
    align-items: center;
    position: fixed;
    right: 25px;
    bottom: 50px;
    border-radius: 50%;
    box-shadow: 0 10px 16px -8px black;
    cursor: pointer;
}
.diverAddBtn:hover{
   opacity: 0.7;
}
.border-transparent {
    border: transparent !important;
}
.br-10 {
    border-radius: 10px !important;
}
.sortby-box table th {
    color: #8f9fb4;
    font-weight: 600;
    font-size: 13px;
    border: none !important;
    white-space: nowrap;
}
.status-loaded {
    color: #17c903 !important;
}
.status-waiting {
    color: #f51f3c !important;
}
.driver-status-title {
    font-size: 12px;
    font-weight: 500;
}
.fs-12 {
    font-size: 12px !important;
}
.drivers-table td, .drivers-table th {
    vertical-align: middle;
}
.bb1pxLight {
    height: 2px;
    width: 100%;
    background: #8f9fb4;
    opacity: .3;
}

.verhicleForm {
    color: #858796;
    position: relative;
}
.verhicleForm .head {
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
    color: var(--themeBg);
    font-weight: bold;
}
.verhicleForm input {
    background-color: var(--themeBgLight);
    border: none;
}
.verhicleForm .form-control {
    background-color: var(--themeBgLight);
    border: none;
}
.verhicleForm .select__control {
    background-color: var(--themeBgLight) !important;
    border: none;
}
.verhicleForm .addLogo .title {
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 5px;
}
.verhicleForm .addLogo label {
    width: 100%;
    height: 150px;
    border: 1px dashed #a5b4c7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}
.verhicleForm .addLogo label img {
    max-height: 100%;
}
.verhicleForm .addLogo input[type=file] {
    display: none;
}
.verhicleForm .closeIco {
    font-size: 20px;
    position: absolute;
    right: 0px;
    top: -8px;
    cursor: pointer;
}
.verhicleForm .themeBtn:hover {
    transform: unset;
    background: var(--themeBg);
    border-color: var(--themeBg);
}
.addVForm {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
}
.dib {
    display: inline-block;
}
.verhicleForm .setFeatured {
    font-weight: bold;
}
.verhicleForm .setFeatured input{
    position: relative;
    margin-left: 0;
    margin-right: 4px;
}
.verhicleForm .featureMain {
    padding: 20px;
    border-radius: 12px;
    background-color: var(--themeBgLight);
}
.verhicleForm .featureMain .selectAll {
    cursor: pointer;
}
.verhicleForm .featureMain ul {
    list-style: none;
    /* padding: 0; */
    padding-left: 20px;
}
.verhicleForm .featureMain ul li {
    font-size: 16px;
    padding: 3px 0;
}
.verhicleForm .featureMain ul li .form-check-input {
    cursor: pointer;
}
.verhicleForm .imagesMain .imgCardM {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;

}
.verhicleForm .imagesMain .imgCardM .imgCard {
    width: 180px;
    height: 150px;
    padding: 7px;
    border: 2px solid var(--themeBgLight);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
}
.verhicleForm .imagesMain .imgCardM .imgCard .deleteImg {
    position: absolute;
    color: var(--themeRed);
    right: 5px;
    top: 5px;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
}
.verhicleForm .imagesMain .imgCardM .imgCard img {
    object-fit: cover;
}
.verhicleForm .note {
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
}
.verhicleForm .note span {
    font-size: 13px;
    color: var(--themeRed);
    display: inline-block;
    margin-left: 5px;
}
